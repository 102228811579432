import React, { useEffect } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';

function ClassData({ index, handleClassChange, removeClass, cls, daysOfWeek, toggleDay, dateParser }) {
	useEffect(() => {
		if (cls.initTime && cls.duration) {
			handleClassChange(index, 'endTime', dateParser(cls.initTime).add(cls.duration, 'minutes').format('HH:mm'));
		}
	}, [cls.duration, cls.initTime]);


	return <div key={index} className="class-data border p-3 mb-3">
		<div key={index} className="d-flex align-items-center justify-content-between mb-2">
			<h5>Clase {index + 1}</h5>
			<Button
				variant="danger"
				onClick={() => removeClass(index)}
				style={{
					padding: '0.2rem 0.5rem',
					fontSize: '0.8rem',
					width: '30px',
					height: '30px',
				}}
			>
			</Button>
		</div>
		<Form noValidate validated = {cls.validationErrors.length}>
			<Row>
				<Col md={6}>
					<FloatingLabel
						controlId={`className-${index}`}
						label='Nombre de la Clase'
						className="mb-3"
					>
						<Form.Control
							type='text'
							isInvalid={cls.validationErrors.name}
							value={cls.name}
							onChange={(e) => handleClassChange(index, 'name', e.target.value)}
							placeholder='Nombre de la Clase'
							required
						/>
						<Form.Control.Feedback type="invalid">
							{cls.validationErrors.name}
						</Form.Control.Feedback>
					</FloatingLabel>
				</Col>
				<Col md={3}>
					<FloatingLabel
						controlId={`classInitTime-${index}`}
						label='Hora de Inicio'
						className="mb-3"
					>
						<Form.Control
							type='time'
							value={cls.initTime}
							isInvalid={cls.validationErrors.initTime}
							onChange={(e) => handleClassChange(index, 'initTime', e.target.value)}
							placeholder='Hora de Inicio'
							min="07:00"
							max="22:30"
							required
						/>
						<Form.Control.Feedback type="invalid">
							{cls.validationErrors.initTime}
						</Form.Control.Feedback>
					</FloatingLabel>
				</Col>
				<Col md={3}>
					<FloatingLabel
						controlId={`classEndTime-${index}`}
						label='Hora de Fin'
						className="mb-3"
					>
						<Form.Control
							readOnly
							type='time'
							value={cls.endTime}
							placeholder='Hora de Fin'
							min="07:30"
							max="23:00"
							required
						/>
					</FloatingLabel>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<div className="d-flex flex-wrap mb-2">
						{daysOfWeek.map(day => (
							<Button
								key={day.value}
								variant={cls.weekDays.includes(day.value) ? 'primary' : 'secondary'}
								className="m-1"
								onClick={() => toggleDay(index, day.value)}
							>
								{day.label}
							</Button>
						))}
					</div>
				</Col>
				<Col md={3}>
					<FloatingLabel
						controlId={`classDuration-${index}`}
						label='Duracion (minutos)'
						className="mb-3"
					>
						<Form.Control
							type='number'
							isInvalid={cls.validationErrors.duration}
							value={cls.duration}
							onChange={(e) => handleClassChange(index, 'duration', e.target.value)}
							placeholder='Duracion (minutos)'
							min={0}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{cls.validationErrors.duration}
						</Form.Control.Feedback>
					</FloatingLabel>
				</Col>
			</Row>
		</Form>
	</div>;

};

export default ClassData;