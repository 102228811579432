import React, { useEffect, useRef, useState } from 'react';
import DynamicTable from '../../components/DynamicTable/DynamicTable';
import useExercises from '../../hooks/useExercises';
import useQueryFilters from '../../hooks/useQueryFilters';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { FaDumbbell } from 'react-icons/fa';
import { BsPlusCircle } from 'react-icons/bs';
import './ExerciseList.css';
import ExerciseEditor from './ExerciseEditor/ExerciseEditor';
import MessageBox from '../../components/MessageBox';
import _exercises from '../../services/exercise.service';
import { toast } from 'react-toastify';

export default function ExerciseList() {
	const { searchParams, fields, setPage } = useQueryFilters({ baseUrl: '/AdminScreen/exercises' });
	const { exercises, loading, exerciseCount, error, reloadExercises } = useExercises(searchParams);
	const [editorIsOpen, setEditorIsOpen] = useState(false);
	const [selectedExercise, setSelectedExercise] = useState(null);
	const openEditor = () => setEditorIsOpen(true);
	const closeEditor = () => {
		setEditorIsOpen(false);
		setSelectedExercise(null);
	};
	const firstLoad = useRef(true);

	useEffect(() => {
		if(!firstLoad.current) {
			reloadExercises();
		} else {
			firstLoad.current = false;
		}
	},[fields.page]);

	const editHandler = (exercise) => {
		setSelectedExercise(exercise);
		openEditor();
	};

	const deleteHandler = async (exercise) => {
		try {
			await _exercises.delete(exercise._id);
			toast.success('Ejercicio eliminado con éxito.');
			onSuccessCreate();
		} catch (ex) {
			console.error(ex);
			toast.error('Error. No se pudo eliminar el ejercicio.');
		};
	};

	const onSuccessCreate = () => {
		reloadExercises();
	};

	return (

		<div id='admin-exercises-screen' className='container admin-con'>
			<ExerciseEditor exerciseParam={selectedExercise}
				show={editorIsOpen} close={closeEditor} success={onSuccessCreate} />
			<Row className='justify-content-between w-100 mt-5 mb-4'>
				<h1 className='section-title'>
					<FaDumbbell className='me-1' />
					Ejercicios
				</h1>
				<Button
					className="btn btn-dark m-1 fixed-right w-auto"
					value="Crear cuponera"
					onClick={openEditor}
				>
					<span className='d-flex align-items-center'>
						<BsPlusCircle className='me-1' />
						Crear ejercicio
					</span>
				</Button>
			</Row>
			<Row className='w-100 justify-content-center'>
				{exercises.length || loading ? <DynamicTable
					data={exercises}
					loading={loading}
					error={error}
					count={exerciseCount}
					onPageChange={setPage}
					page={parseInt(fields.page)}
					pageSize={parseInt(fields.pageSize)}
					columnInfo={[
						{
							name: {
								title: 'Nombre',
								type: 'text',
								label: 'Nombre',
							}
						},
						{
							videoUrl: {
								title: 'URL del video',
								type: 'text',
								label: 'URL del video',
							}
						},
					]}
					actionButtons={{
						editHandler,
						deleteHandler
					}}
					dataName='exercises'
					showCheckboxColumn={false}
				/> :
					<MessageBox className={'my-2'}>No se encontraron ejercicios</MessageBox>
				}
			</Row>
		</div>
	);
}
