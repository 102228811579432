export default class Exercise {
	constructor(name, videoUrl, image, id = '') {
		this.name = name;
		this.videoUrl = videoUrl;
		this.image = image;
		this._id = id;
	}
	/**
  *
  * @param {string} name
  * @param {string} videoUrl
  * @param {*} image
  * @returns {boolean}
  */

	static validate(name, videoUrl, image) {
		return validator(name, videoUrl, image);
	}
	isValid(name, videoUrl, image) {
		return validator(name, videoUrl, image);
	}
	/**
  *
  * @param {string} name
  * @param {string} videoUrl
  * @param {*} image
  * @returns {Exercise}
  */
	static fromObject({ name, videoUrl, image, _id = ''}) {
		return new Exercise(name, videoUrl, image, _id);
	}
	/**
  *
  * @returns {Exercise}
  */
	static createEmpty() {
		return new Exercise('', '', '');
	}
}
const validator = (name, videoUrl, image) => {
	return (
		name?.trim() !== '' &&
  videoUrl?.trim() !== '' &&
  (typeof image === 'object' || image?.trim() !== '')
	);
};
