

export const sections = {
	home: {
		id: 'acceder',
		title: 'Acceder',
		hash: '',
		overlay: true,
		to: '/signin',
		image: 'https://s3-alpha-sig.figma.com/img/a596/7fa9/c394ac95ee537e8f37b91cc8733e846d?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cwAOxjl2119Jpe21PQ5jFNHpXxE33JW3XP11O6fEYXLLJxbnHH7~iYqaGmPv9v-M40QGXjfpGzjSIb0obnDRCFry400x0ZG9bpn7gKkNiYA-xgwXGW5yDbQcJguUgrSKKun8Y54AUcEEzSBv0016fKkgvSLLKNaKjDhyWniDre~NpH-r81050CIq6WdqCqjZ7KW2uZW0fROPPuv2xJ7MQgYjzenFh0Shp0NSqfw~06kjLhdxsgTPPBOY73UvrErTGWqpM~0gAr4TcXdvhCGJ1D~ImKTf9RUsLTQr5R~AOzuk7x0rx4gEcMTbc32wtOXDO8uEx859g~kAIJL1Ax7pvA__',
		content: [
			{
				caption: 'entrena',
				tag: 'h1',
			},
			{
				caption: 'con',
				tag: 'h2',
			},
			{
				caption: 'nosotros',
				tag: 'h2',
			},
			{
				caption: 'llega a tu mejor versión',
				tag: 'h3',
			},
		],
		buttonCaption: 'acceder'
	},
	about: {
		id: 'crossfit',
		hash: '#crossfit',
		title: 'Crossfit',
		overlay: true,
		image: 'https://s3-alpha-sig.figma.com/img/8a0a/d916/02d5d5f0716384aba57eb4a89ddc2752?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JZN8W2snoqRaRTg-2J23Qm8QCXWBvSm3zB9edCuFgrnoRYlMy9a1PBR1ME9DC3mwVw1oJT3GScgbA7w9pypfZ8t9pa3WSFHtfh12ATJsfRTJg~TFiOwOq35JhAHEDrWiUE~Uabt90qd8pe5l4ZvTXVYmdaa~E7fQdsEWGjz2Bn2re~Ovk9EH5xtZl2rY86qiRBIyj-gOVcoVesR-HD710AxEWSRconQSfYKqb2J3bwP6MV6ruE3IBNjNW3T6uJjO~8s2mRK-UdwnRc-sNx6pGK-vfNgSA1s1Wtod1mRfjJHVOWfoABYM5ESS1jUM8vNaQ0x4dDDEHSG4xpMfwFdF3Q__',
		content: [
			{
				caption: 'crossfit',
				tag: 'h1',
			},
			{
				caption: `CrossFit es una técnica de entrenamiento que conecta movimientos de diferentes disciplinas, tales como la halterofilia, el entrenamiento metabólico o el gimnástico.
                Consiste en acometer un programa de ejercicios (flexiones, tracción, etc), en un tiempo determinado y con un número definido de veces.
                Esta noción tiene como principio mantener intacta la motivación de los deportistas a largo plazo. Se puede realizar de manera individual o en grupo, y las sesiones suelen ser cortas, variadas y adaptables en función del nivel de cada participante.`,
				shortCaption: 'CrossFit es una técnica de entrenamiento que conecta movimientos de diferentes disciplinas, tales como la halterofilia, el entrenamiento metabólico o el gimnástico...',
				tag: 'p',
			}
		],
		linkCaption: 'ver más'

	},
	location: {
		id: 'instalaciones',
		hash: '#instalaciones',
		title : 'Instalaciones',
		overlay: true,
		image: 'https://s3-alpha-sig.figma.com/img/60ed/b2ca/ec613e82f454e652c94f88075254cf2d?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FXX61~H3n4LywjWykkvedwreOuaobCbetROynRtoPGDXG9D8HUzY~yR-UiQToPvTFaU-INiKmtVx7ha9J931rax2sXpZl1t2u0VHFhgCtuSWTRhEk6lE6RNIOsj7~SjzT4jeAZWLNVcTnKCHecViVSJA9KOOlB~g9TjjkIHb6ME9XEFUF5CwDExetMyqkMoq2JwbDKzSO7xTOqHW-DscPwHMAxSSXOr25gEZX41JUuRsmqwhwE53WMcq1-gENs8JFvVvsBz-7D3-UbdgfDWtsq7TS38tHN4pJZN~KS9qHIWHwWA~~z0xEZDlnFZoRijeLcVDnqP7-k7-oGkOiwScNg__',
		content: [
			{
				caption: 'box',
				tag: 'h1',
			},
			{
				caption: 'Un BOX, es el lugar acondicionado para entrenar Crossfit®. Los puedes encontrar en diferentes tamaños, disposiciones y diseños.',
				tag: 'p',
			}
		],
		linkCaption: 'ver más'
	},
	schedules: {
		id: 'horarios',
		hash: '#horarios',
		title: 'Horarios',
		overlay: true,
		image: 'https://s3-alpha-sig.figma.com/img/bd9c/c033/5bc266f31f30ac9de130da51ec9211ef?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=l6lkzQMBRcFFschFfWgHACxwCf5ikV3-QI~r4lgIHf7ed33406Bl0gvFW2KTokJfqtEc9MscwJzx83avsVb80d7gX2vRRR9D~zkWGv3ZEkx-Dtis1mLnblCwIl9zG3AdYOCjTIN-rTts9kPL7XwGci3ugN8-icLEPrJo8p26-zA3tiGLRamoElWeIV02mHQPYd8XaZMAPowCAg9UgRZi1hC--oIkAXYjXrVGYcy3LUQDsf0187JRd6NiyordYPyRqEsWTnupZG9zB6MDw~JiJzos1vXv5pEJsOKW779o3bpjiwqPs-BW3gcxnFn0b8eOxbjA0RY3nOdEHwFYdVZtvQ__',
		content: [
			{
				caption: 'horarios',
				tag: 'h1'
			},
			{
				caption: 'lorem ipsum',
				tag: 'p'
			}
		],
		linkCaption: 'ver más'
	}
}; 