import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { getError, maxitemsPerPage } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import { BsPlusCircle } from 'react-icons/bs';
import moment from 'moment';
import debtService from '../../services/debt.service';
import classnames from 'classnames';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			debts: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UserDebtsList({ className }) {
	const [{ loading, error, debts, successDelete, itemQuantity }, dispatch] =
		useReducer(reducer, {
			debts: [],
			loading: true,
			error: '',
			itemQuantity: 0
		});

	const params = useParams();
	const { id } = params;
	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await debtService.getDebtsByUserId(id);

				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function newPaymentHandler() {
		navigate(`/AdminScreen/debts/newpayment/${id}`);
	}

	async function goToPaymentsHandler() {
		navigate(`/AdminScreen/debts/payments/${id}`);
	}

	// async function userAttendeesHandler(user) {
	//   navigate(`/AdminScreen/userattendees/${user._id}`);
	// }

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div className={classnames('', { [className]: className })}>
				<Row >
					<Col className='d-flex justify-content-end mb-2'
					>
						<Button
							className="btn btn-dark m-1 fixed-right"
							value="Crear Pago"
							onClick={() => newPaymentHandler()}
						>
							<span>
								<BsPlusCircle /> Registrar Pago
							</span>
						</Button>
						<Button
							className="btn btn-dark m-1 fixed-right"
							value="Crear Pago"
							onClick={() => goToPaymentsHandler()}
						>
							<span> Ver Pagos</span>
						</Button>
					</Col>
				</Row>
				{loading ? (
					<LoadingBox></LoadingBox>
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<Table bordered hover responsive size="sm">
						<thead>
							<tr>
								<th className="col-md-2">
									<span>Usuario</span>
								</th>
								<th className="col-md-2">Concepto</th>
								<th>Monto</th>
								<th className="col-md-1">Fecha</th>
								<th>Pago</th>
								{/* <th className='col-1'>Opciones</th> */}
							</tr>
						</thead>
						{debts ?
							<tbody >
								{currentPosts.map((debt) => (
									<tr key={debt._id}
										className="align-items-center table-order"
										id="data"
									>
										<td className="col-md-2">{debt.user.name}</td>
										{debt.userbooking ? (
											<td className="col-md-2">{debt.userbooking.template.name}</td>
										) : (
											<td></td>
										)}
										{debt.userbooking ? (
											<td className="col-md-2">${debt.userbooking.template.price}</td>
										) : (
											<td></td>
										)}
										<td className="col-md-1">
											{moment(debt.date).format('DD-MM-YYYY hh:mm a')}
										</td>
										<td className="col-md-1">
											{debt.paid ? 'confirmado' : 'pendiente'}
										</td>
									</tr>
								))}
							</tbody>
							: ''}
					</Table>
				)}
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</>
	);
}

export default UserDebtsList;
