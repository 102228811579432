import React, { useEffect, useReducer } from 'react';
import Product from '../../components/Product/Product.jsx';
import LoadingBox from '../../../../components/LoadingBox.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MessageBox from '../../../../components/MessageBox.js';
import productService from '../../services/product.service.js';
import './CartHomeScreen.css';
import CartNavbar from '../../components/CartNavbar/CartNavbar.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, products: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function CartHomeScreen({ productType }) {
	const [{ loading, error, products }, dispatch] = useReducer(reducer, {
		products: [],
		loading: true,
		error: '',
	});

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await productService.list(productType);  // Pass the product type to the service
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
		}
	};


	return (
		<div className='cart-home-screen package-screen'>
			<section className='title-and-cart'>
				<div className='title'>
				CUPONERAS
				</div>
				<CartNavbar/>

			</section>
			<section className="products">
				{loading ? (
					<LoadingBox />
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<>
						{products.some(p => p.highlightedProduct && p.active) && (
							<Row className="w-100">
								<h1 className="text-center col-12 titles">Productos destacados</h1>
							</Row>
						)}
						<Row className="w-100 px-1">
							{products.map((product) =>  product.active || product.isActive ? (
								<Col key={product.slug} sm={12} md={6} lg={4} xl={3} className="mb-3">
									<Product product={product} productType={productType} /> {/* Pass productType */}
								</Col>
							) : null
							)}
						</Row>
					</>
				)}
			</section>
		</div>
	);
}

export default CartHomeScreen;
