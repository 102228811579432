import React from 'react';
import './LoadingOverlay.css';
import Spinner from 'react-bootstrap/Spinner';
export default function LoadingOverlay() {
	return (
		<div className="loading-overlay">
			<Spinner animation="border"/>
		</div>
	);
}
