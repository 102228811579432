import React, { useReducer, useState } from 'react';
import bookingTemplateService from '../../services/membershipTemplate.service';
import { Helmet } from 'react-helmet-async';
import {
	Button,
	Modal,
	Form,
	Container,
	Row,
	Col,
	Card,
	Stack
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddBookingTemplate() {
	const navigate = useNavigate();
	const [name, setName] = useState('');
	const [price, setPrice] = useState('');
	const [daysLast, setDaysLast] = useState('');
	const [totalBookings, setTotalBookings] = useState('');
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: ''
	});

	const addBookingTemplateHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const bookingTemplateData = {
				name,
				price,
				daysLast,
				totalBookings
			};
			await bookingTemplateService.createBookingMembership(bookingTemplateData);
			toast.success('Cuponera creada');
			navigate('/AdminScreen/bookings');
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear cuponera</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear cuponera?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addBookingTemplateHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear cuponera</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Crear cuponera
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Nombre:</Form.Label>
													<Form.Control
														type="text"
														required
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="price">
													<Form.Label>Precio:</Form.Label>
													<Form.Control
														type="number"
														required
														onChange={(e) => setPrice(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="daysLast">
													<Form.Label>Duracion:</Form.Label>
													<Form.Control
														type="number"
														required
														onChange={(e) => setDaysLast(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="totalBookings">
													<Form.Label>Maximo de reservas:</Form.Label>
													<Form.Control
														type="number"
														required
														onChange={(e) => setTotalBookings(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Crear cuponera
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddBookingTemplate;
