import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
	Card
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import { BsPlusCircle, BsTrash, BsFileEarmarkRichtext } from 'react-icons/bs';
import { BsFillFileEarmarkFill } from 'react-icons/bs';
import wodService from '../../services/wod.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			WODs: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function WODList() {
	const [{ loading, error, WODs, successDelete, itemQuantity }, dispatch] =
		useReducer(reducer, {
			WODs: [],
			loading: true,
			itemQuantity: 0,
			error: ''
		});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination PENDING
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = WODs.slice(indexOfFirstPost, indexOfLastPost);

	//change page PENDNG
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);

		currentPosts = WODs.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await wodService.getAllWods();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, userInfo.token]);

	async function deleteWODHandler(WOD) {
		if (window.confirm('Seguro desea eliminar esta WOD?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await wodService.deleteWod(WOD._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('WOD eliminada');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}
	async function detailsWODHandler(WOD) {
		navigate(`/AdminScreen/editWOD/${WOD._id}`);
	}
	async function newWODHandler() {
		navigate('/AdminScreen/newWOD');
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de WODs</title>
			</Helmet>

			<div className="container admin-con">
				<div className="tableResponsive">
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className="section-title text-right">
								<BsFillFileEarmarkFill></BsFillFileEarmarkFill>Lista de WODs
							</h1>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear cuponera"
								onClick={() => newWODHandler()}
							>
								<span>
									<BsPlusCircle />
									Crear WOD
								</span>
							</Button>
						</Col>
					</Row>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table bordered hover responsive size="sm">
								<thead>
									<tr>
										<th>
											<span>Nombre</span>
										</th>
										<th>
											<span>Fecha</span>
										</th>
										<th>
											<span>Documento</span>
										</th>
										<th className="col-1">Opciones</th>
									</tr>
								</thead>
								<tbody>
									{WODs
										? currentPosts.map((WOD) => (
											<tr
												className="align-items-center table-order"
												id="data"
												key={WOD._id}
											>
												<td className="col-md-2">{WOD.name}</td>
												<td className="col-md-2">{WOD.date}</td>
												{WOD.image ? (
													<td className="col-md-2">{WOD.image.s3_key}</td>
												) : (
													<td className="col-md-2"></td>
												)}
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														<Dropdown.Item
															eventKey="0"
															onClick={() => detailsWODHandler(WOD)}
														>
															<BsFileEarmarkRichtext /> Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="2"
															onClick={() => deleteWODHandler(WOD)}
														>
															<BsTrash /> Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										))
										: ''}
								</tbody>
							</Table>
						</Card>
					)}
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default WODList;
