import LoadingBox from '../../components/LoadingBox';
import React, { useContext, useReducer, useState, useEffect } from 'react';
import { Store } from '../../Store';
import { Helmet } from 'react-helmet-async';
import { Button, Col, Container, Row, Card } from 'react-bootstrap';
import { getError } from '../../utils';
import eventService from '../../services/event.service';
import { useNavigate } from 'react-router-dom';
import { Scheduler } from '@aldabil/react-scheduler';
import { es } from 'date-fns/locale';
import moment from 'moment';
import '../Screens.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true, loadingFormatedData: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			events: action.payload,
			loading: false,
			loadingFormatedData: false
		};
	case 'FETCH_FAIL':
		return {
			...state,
			loading: false,
			loadingFormatedData: false,
			error: action.payload
		};
	default:
		return state;
	}
};

function userAvailableEvents() {
	const navigate = useNavigate();
	const [{ loading, loadingFormatedData }, dispatch] = useReducer(reducer, {
		events: [],
		loading: true,
		loadingFormatedData: true,
		error: ''
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const [selectedStartDate, setSelectedStartDate] = useState(
		moment(new Date()).format('YYYY-MM-DD')
	);
	const [selectedEndDate, setSelectedEndDate] = useState(
		moment(new Date()).format('YYYY-MM-DD')
	);

	function disponibility(event) {
		if (event.capacity === event.users) {
			return 'bg-warning';
		} else if (event.capacity > event.users) {
			return 'bg-success';
		} else {
			return 'bg-danger';
		}
	}

	function disponibilityCalendar(capacity, users) {
		if (capacity === users) {
			return '#ffc107';
		} else if (capacity > users) {
			return '#28a745';
		} else {
			return '#dc3545';
		}
	}

	async function getRemoteEvents({ start, end }) {
		try {
			const startDate = moment(new Date(start)).format('YYYY-MM-DD');
			const endDate = moment(new Date(end)).format('YYYY-MM-DD');
			const data = await eventService.getCalendarData(startDate, endDate);

			const events = data.map((event) => ({
				event_id: event._id,
				title: event.subject,
				start: new Date(event.eventStartDateTime),
				end: new Date(event.eventEndDateTime),
				location: event.location,
				capacity: event.capacity,
				users: event.users,
				editable: false,
				deletable: false,
				color: disponibilityCalendar(event.capacity, event.users)
			}));

			return events;
		} catch (err) {
			console.error(err);
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await eventService.getCalendarData(
					selectedStartDate,
					selectedEndDate
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		setSelectedStartDate(moment(new Date()).format('YYYY-MM-DD'));
		setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));
		fetchData();
	}, [userInfo.token]);

	async function eventDetailUserHandler(event) {
		navigate(`/userEventDetails/${event.event_id}`);
	}
	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			<Helmet>
				<title>Calendario de Eventos</title>
			</Helmet>

			{loading || loadingFormatedData ? (
				<LoadingBox></LoadingBox>
			) : (
				<div>
					<div className="container admin-con">
						<Row className="d-flex flex-row w-100 justify-content-center">
							<Col md={12} lg={10} xs={12}>
								<div className="borderLine"></div>
								<Card className="shadow cardCalendar">
									<Card.Body>
										<div className="mb-3 mt-md-4">
											<h2 className="fw-bold mb-4 text-uppercase text-align-center">
												Eventos
											</h2>
											<div className="mb-3">
												<div className="mb-3">
													<Scheduler
														getRemoteEvents={getRemoteEvents}
														locale={es}
														hourFormat="12"
														translations={{
															navigation: {
																month: 'Mes',
																week: 'Semana',
																day: 'Dia',
																today: 'Hoy'
															},
															form: {
																addTitle: 'Add Event',
																editTitle: 'Edit Event',
																confirm: 'Confirm',
																delete: 'Delete',
																cancel: 'Cancel'
															},
															event: {
																title: 'Title',
																start: 'Start',
																end: 'End',
																allDay: 'All Day'
															},
															moreEvents: 'More...'
														}}
														month={{
															weekDays: [0, 1, 2, 3, 4, 5],
															weekStartOn: 6,
															startHour: 8,
															endHour: 2,
															step: 60,
															navigation: true,
															cellRenderer: () => {
																return <Button disabled={true}></Button>;
															}
														}}
														week={{
															weekDays: [0, 1, 2, 3, 4, 5, 6],
															weekStartOn: 6,
															startHour: 5,
															endHour: 22,
															step: 60,
															navigation: true,
															cellRenderer: () => {
																return <Button disabled={true}></Button>;
															}
														}}
														day={{
															startHour: 8,
															endHour: 22,
															step: 60,
															navigation: true,
															cellRenderer: () => {
																return <Button disabled={true}></Button>;
															}
														}}
														viewerExtraComponent={(fields, event) => {
															return (
																<div>
																	<p>Locacion: {event.location}</p>
																	<Container className={`${disponibility(event)}`}>
																		<p>
																			Capacidad: {event.users}/{event.capacity}
																		</p>
																	</Container>
																	<Button
																		type="submit"
																		className="mx-auto"
																		onClick={() => {
																			eventDetailUserHandler(event);
																		}}
																	>
																		Ver detalles
																	</Button>
																</div>
															);
														}}
													/>
												</div>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			)}
		</div>
	);
}
export default userAvailableEvents;
