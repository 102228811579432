import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const USER_API_URL = '/users';

class UserService {
	async getAllUsers() {
		try {
			const response = await restClient.get(USER_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getAdminUsers() {
		try {
			const response = await restClient.get(`${USER_API_URL}/admins`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async signIn(email, password) {
		try {
			const response = await restClient.post(`${USER_API_URL}/signin`, {
				email,
				password
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async signUp(name, email, password) {
		try {
			const response = await restClient.post(`${USER_API_URL}/signup`, {
				name,
				email,
				password
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createUser(name, email, isAdmin) {
		try {
			const response = await restClient.post(`${USER_API_URL}/createUser`, {
				name,
				email,
				isAdmin
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async deleteUser(userId) {
		try {
			const response = await restClient.delete(`${USER_API_URL}/${userId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getUserById(userId) {
		try {
			const response = await restClient.get(`${USER_API_URL}/${userId}`);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async verifyUser(verifyToken) {
		try {
			const response = await restClient.put(
				`${USER_API_URL}/verifyUser/${verifyToken}`
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async forgotPassword(email) {
		try {
			const response = await restClient.post(`${USER_API_URL}/forgotPassword`, {
				email
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async resetPassword(resetToken, newPassword) {
		try {
			const response = await restClient.put(
				`${USER_API_URL}/resetPassword/${resetToken}`,
				{
					password: newPassword
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateUser(userId, name, email, password) {
		try {
			const response = await restClient.put(`${USER_API_URL}/${userId}`, {
				name,
				email,
				password
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getBasicUserList() {
		try {
			const response = await restClient.get(`${USER_API_URL}/soft/users`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async verify(token) {
		const res = await restClient.put(`${USER_API_URL}/verifyUser/${token}`);
		return res;
	}
}

export default new UserService();
