import React, {useReducer, useState ,useRef,useEffect} from 'react';
import {
	Button,
	Modal,
	Form,
	Container,
	Row,
	Col,
	Card,
	Stack
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import DateTimePicker from 'react-datetime-picker';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import rmService from '../../services/rm.service';
import useQueryFilters from '../../hooks/useQueryFilters';
import useExercises from '../../hooks/useExercises';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_EVENTS_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_EVENTS_SUCCESS':
		return {
			...state,
			events: action.payload,
			loadingFetch: false,
		};
	case 'FETCH_EVENTS_FAIL':
		return { ...state, loadingFetch: false, error: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };

	default:
		return state;
	}
};


function NewRM() {
	const navigate = useNavigate();
	const params = useParams();
	const { id } = params;
	const {searchParams} = useQueryFilters({ baseUrl: '/AdminScreen/rms' });
	const { exercises, loading, reloadExercises } = useExercises(searchParams);
	const firstLoad = useRef(true);

	const [state, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: '',
		colors:[],
		rms:[],
	});

	const { loadingFetch, loadingCreate} = state;

	const [user, setUser] = useState([]);
	const [maxRep, setMaxRep] = useState(1);
	const [weight, setWeight] = useState(1);
	const [date, setDate] = useState(new Date());
	const [selectedExercise, setSelectedExercise] = useState([]);
	const [exercisesList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);


	useEffect(() => {
		setUser(id);
		if (!firstLoad.current) {
			reloadExercises();
		} else {
			firstLoad.current = false;}
	}, [id]);

	const handleDateChange = (newDate) => {
		const momentDate = moment(newDate);
		momentDate.startOf('day');
		const updatedDate = momentDate.toDate();
		setDate(updatedDate);
	};

	const addRmHandler = async () => {
		const exercise = selectedExercise[0]?._id;
		if (!user || !exercise || !maxRep || !weight || !date ) {
			toast.error('Para crear WOD debe completar la informacion y subir o generar y guardar la imagen');
			return;
		}
		try {

			dispatch({ type: 'CREATE_REQUEST' });
			await rmService.createRm(user,exercise,maxRep,weight,date);
			toast.success('RM creado');
			navigate(`/AdminScreen/userbookinglist/${id}`);
		} catch (ex) {
			toast.error(getError(ex));
		}
		handleClose();
	};


	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && loading && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear RM</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear RM?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addRmHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear RM</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Crear RM
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()} className='col'>
												<Row>
													<Form.Group className="mb-3 col-lg-4" controlId="fecha">
														<Form.Label>Fecha</Form.Label>
														<br></br>
														<DateTimePicker
															className="timePicker"
															onChange={handleDateChange}
															value={date}
															clearIcon={null}
															required={true}
															disableClock={true}
															format="dd-MM-y"
															minDate={new Date()}
														/>
													</Form.Group>
												</Row>
												<h2 className='mb-3'>Lista de ejercicios</h2>
												<Row>
													<Form.Group className="col-lg-6">
														<Form.Label>Buscar Ejercicio</Form.Label>
														<Typeahead
															id="exercise-search"
															options={[
																...exercises,
																{ _id: 'add-new', name: 'Agregar nuevo ejercicio', emptyOption: true }
															]}
															placeholder="Seleccione un ejercicio"
															labelKey="name"
															selected={selectedExercise}
															onChange={(selected) => {
																if (selected[0] && selected[0]._id === 'add-new') {
																	navigate('/AdminScreen/exercises');
																} else {
																	setSelectedExercise(selected);
																}
															}}
														/>
													</Form.Group>
												</Row>
												{exercisesList.map((item, index) => (
													<Row key={index} className="mb-3">
														<Form.Group className="col-lg-6">
															<Form.Label>Ejercicio</Form.Label>
															<Form.Control
																type="text"
																value={item.exercise.name}
																readOnly
															/>
														</Form.Group>
													</Row>
												))}
												<Row>
													<Form.Group className="mb-3 col-lg-6" controlId="timeCap">
														<Form.Label>Maximo Repeticiones</Form.Label>
														<Form.Control
															type="number"
															min={0}
															onChange={(e) => setMaxRep(e.target.value)}
														></Form.Control>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3 col-lg-6" controlId="weight">
														<Form.Label>Peso</Form.Label>
														<Form.Control
															type="number"
															min={0}
															onChange={(e) => setWeight(e.target.value)}
														/>
													</Form.Group>
												</Row>
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Crear RM
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default NewRM;

