import axios from '../restClient';
import { handleErrors } from './common.service';
import WeeklyTemplate from '../classes/WeeklyTemplate';
import { getParamsObj } from '../utils';

const BASE_URI = '/weeklyTemplates';

class WeeklyTemplateService {
	async list(searchParams,nonPaginated = false) {
		const query = getParamsObj(searchParams);
		if(nonPaginated) {
			query.nonPaginated = true;
		}
		try {
			const response = await axios.get(BASE_URI,{
				params: query
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
	async create(name, dateFrom, dateTo, isActive) {
		if(WeeklyTemplate.validate(name,dateFrom, dateTo, isActive)) {
			try {
				const response = await axios.post(BASE_URI,{
					name,dateFrom, dateTo, isActive
				});
				return response;
			} catch (error) {
				handleErrors(error);
			}
		} else {
			throw new Error('Error. Los datos ingresados no son válidos.');
		}

	}
	async edit(id,name, dateFrom, dateTo, isActive) {
		if(WeeklyTemplate.validate(name, dateFrom, dateTo, isActive)) {
			try {
				const response = await axios.put(BASE_URI,{
					name, dateFrom, dateTo, isActive
				},{
					params: { id }
				});
				return response;
			} catch (error) {
				handleErrors(error);
			}
		} else {
			throw new Error('Error. Los datos ingresados no son válidos.');
		}

	}
	async delete(id) {
		try {
			const response = await axios.delete(BASE_URI,{
				params: { id }
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
}
export default new WeeklyTemplateService();
