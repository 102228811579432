import React from 'react';
import './WeeklyTemplateCalendar.css';

const daysOfWeek = [
	{ label: 'Lunes', value: 'L' },
	{ label: 'Martes', value: 'M' },
	{ label: 'Miércoles', value: 'X' },
	{ label: 'Jueves', value: 'J' },
	{ label: 'Viernes', value: 'V' },
	{ label: 'Sábado', value: 'S' },
	{ label: 'Domingo', value: 'D' },
];

const color = {};
const getRandomColor = () => {
	const r = Math.floor(Math.random() * 256);
	const g = Math.floor(Math.random() * 256);
	const b = Math.floor(Math.random() * 256);
	return `rgb(${r}, ${g}, ${b})`;
};

const getCachedColor = (id) => {
	if (!color[id]) {
		color[id] = getRandomColor();
	}
	return color[id];
};

export const WeeklyCalendar = ({ classes }) => {

	const getSubgridIndex = (time) => {
		const [hour, minute] = time.split(':').map(Number);//calculate 15 minute slots
		return (hour - 7) * 4 + minute / 15; 
	};
	

	return (
		<div className="weekly-calendar">
			<div className="grid-container">
				<div className="header-cell"></div>
				{daysOfWeek.map((day) => (
					<div key={day.value} className="header-cell day-header">
						{day.label}
					</div>
				))}

				{Array.from({ length: 17 }, (_, i) => i + 7).map((hour) => (
					<React.Fragment key={hour}>
						<div className="time-cell">{hour}:00</div>
						{daysOfWeek.map((day) => (
							<div key={day.value + hour} className="hour-cell">
								{Array.from({ length: 4 }).map((_, i) => {
									const quarterHourIndex = (hour - 7) * 4 + i;
									return (
										<span key={i} className="quarter-hour-slot">
											{classes.map((classItem) => {
												const startSlot = getSubgridIndex(classItem.initTime);
												const endSlot = getSubgridIndex(classItem.endTime);
												const color = getCachedColor(classItem._id);
												const duration = endSlot - startSlot;
												if (
													classItem.weekDays.includes(day.value) &&
                 									quarterHourIndex >= startSlot && 
              									    quarterHourIndex < endSlot
												) {
													return (
														<span
															key={classItem._id}
															className="class-quarter-block"
															style={{
																backgroundColor: color,
																gridRow: `${i + 1} / span ${duration}`,
															}}
														>
															{i === 0 && (
																<span className="class-title">
																	{classItem.name} <br />
																	{classItem.initTime} - {classItem.endTime}
																</span>
															)}
														</span>
													);
												}
												return null;
											})}
										</span>
									);
								})}
							</div>
						))}
					</React.Fragment>
				))}

			</div>
		</div>
	);
};










