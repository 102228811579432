export default class WeeklyTemplate {
	constructor(name, dateFrom, dateTo, isActive, id = '') {
	  this.name = name;
	  this.dateFrom = dateFrom;
	  this.dateTo = dateTo;
	  this.isActive = isActive;
	  this._id = id;
	}
  
	/**
	 * 
	 * @param {string} name
	 * @param {Date|string} dateFrom
	 * @param {Date|string} dateTo
	 * @param {boolean} isActive
	 * @returns {boolean}
	 */
	static validate(name, dateFrom, dateTo, isActive) {
		return validator(name, dateFrom, dateTo, isActive);
	}
	isValid(name, dateFrom, dateTo, isActive) {
		return validator(name, dateFrom, dateTo, isActive);
	}

	/**
	 * 
	 * @param {string} name
	 * @param {Date|string} dateFrom
	 * @param {Date|string} dateTo
	 * @param {boolean} isActive
	 * @returns {WeeklyTemplate}
	 */

	static fromObject({ name, dateFrom, dateTo, isActive, _id = '' }) {
	  return new WeeklyTemplate(name, dateFrom, dateTo, isActive, _id);
	}
  
	/**
	 * @returns {WeeklyTemplate}
	 */
	static createEmpty() {
	  return new WeeklyTemplate('', '', '', false, '');
	}
}

const validator = (name, dateFrom, dateTo, isActive) => {
	return (
		name?.trim() !== '' &&
	new Date(dateFrom) instanceof Date &&
	!isNaN(new Date(dateFrom)) &&
	new Date(dateTo) instanceof Date &&
	!isNaN(new Date(dateTo)) &&
	typeof isActive === 'boolean'
	);
};
  