import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const EVENT_ATTENDEE_API_URL = '/eventAttendees';

class EventAttendeeService {
	async getAllEventAttendees() {
		try {
			const response = await restClient.get(EVENT_ATTENDEE_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createEventAttendee(eventAttendeeData) {
		try {
			const response = await restClient.post(
				EVENT_ATTENDEE_API_URL,
				eventAttendeeData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getEventAttendeeById(eventAttendeeId) {
		try {
			const response = await restClient.get(
				`${EVENT_ATTENDEE_API_URL}/find/${eventAttendeeId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async unsubscribeEventAttendee(user, event) {
		try {
			const response = await restClient.delete(
				`${EVENT_ATTENDEE_API_URL}/unsubscribe`,
				{
					headers: authHeader(),
					data: { user, event }
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async unsubscribeAll(userData) {
		try {
			const response = await restClient.delete(
				`${EVENT_ATTENDEE_API_URL}/unsubscribeAll`,
				{
					headers: authHeader(),
					data: userData // Data to be sent in the request body
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getAllEventAttendeesByEventId(eventId) {
		try {
			const response = await restClient.get(
				`${EVENT_ATTENDEE_API_URL}/event/${eventId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getAllEventsByUserId(userId) {
		try {
			const response = await restClient.get(
				`${EVENT_ATTENDEE_API_URL}/user/${userId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getUserPendingEvents(userId) {
		try {
			const response = await restClient.get(
				`${EVENT_ATTENDEE_API_URL}/userPendingEvents/${userId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async previewUserEvent(eventId, userId) {
		try {
			const response = await restClient.get(
				`${EVENT_ATTENDEE_API_URL}/previewuserevent`,
				{
					params: {
						event: eventId,
						user: userId
					},
					headers: authHeader()
				}
			);
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getNotSignedUsers(eventId) {
		try {
			const response = await restClient.get(
				`${EVENT_ATTENDEE_API_URL}/notsignedusers/${eventId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new EventAttendeeService();
