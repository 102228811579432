import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavbarToggler from '../../NavbarToggler/NavbarToggler';
import classnames from 'classnames';
import { sections } from '../../../screens/LandingPageScreen/nonLoggedSections';

export default function NonLoggedOptions({ handleCollapse, expand }) {
	const { hash, pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const sect = Object.values(sections).find(s => s.hash === hash);
		const foundId = document.querySelector(`#${sect.id}`);
		if (foundId) {
			foundId.scrollIntoView({ behavior: 'smooth' });
		}
	}, [hash, pathname]);
	

	useEffect(() => {
		const scrollListener = () => {
			const sectionNodes = document.querySelectorAll('section');
			sectionNodes.forEach(section => {
				const top = section.getBoundingClientRect().top;
				if (top === 0) {
					const sect = Object.values(sections).find(s => s.id === section.id);
					navigate(`/${sect.hash}`);
				}
			});
		};
		const landingPage = document.querySelector('#landing-page-screen');
		landingPage?.addEventListener('scroll', scrollListener);	
		return () => {
			landingPage.removeEventListener('scroll', scrollListener);};
	}, []);
	
	const location = useLocation();
	return <div className="client-navbar-options">
		<div className="nav-options">
			{
				Object.values(sections).map(opt => {
					return <div className="nav-option" key={opt.title}>
						<Link to={opt.pathname || opt.hash} className={classnames({ 'focused': location.hash === opt.hash })}
							onClick={handleCollapse}>
							<span>{opt.title}</span>
						</Link>
					</div>;
				})
			}
		</div>
		{expand &&
            <div>
            	<NavbarToggler onClick={expand} />
            </div>
		}
	</div >;
}