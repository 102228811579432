import React, { useEffect, useRef, useState } from 'react';
import './Tabs.css';
import classnames from 'classnames';

export default function Tabs({ options}) {
	const bottomBarRef = useRef();
	const containerRef = useRef();
	const tabWidth = 100 / options.length;
	const [styles, setStyles] = useState({
		tabStyle: {
			width: `${tabWidth}%`
		},
		contentStyle: {
			transform: 'translateX(0%)'
		}
	});
	const [activeTab, setActiveTab] = useState(options[0]);
	
	/**
	 * Translates the tab and content to the active tab
	 */
	const translateTab = () => {
		const activeTabIndex = options.findIndex(({ option }) => option == activeTab.option);
		const transformTab = `translateX(${activeTabIndex * 100}%)`;
		const transformContent = `translateX(-${activeTabIndex * 100}%)`;	
		setStyles({
			tabStyle: { width: `${tabWidth}%`, transform: transformTab },
			contentStyle: { transform: transformContent }
		});
	};

	useEffect(() => {
		translateTab();
	}, [activeTab]);

	return <div className="tab-container" ref={containerRef}>
		<div className="tabs">
			{
				options.map((option, index) => {
					return <button className={classnames('tab', { active: activeTab.option == options[index].option })}
						key={index} onClick={() => setActiveTab(option)}>
						{option.option}
					</button>;
				})
			}
		</div>
		<div className='bottom-bar' ref={bottomBarRef}>
			<div className="bottom-bar-fill" style={styles.tabStyle}>
			</div>
		</div>
		<div className="tab-content" style={styles.contentStyle}>
			{
				options.map((option, index) => {
					return <option.cmp className={classnames('content', { active: activeTab.option == options[index].option })} key={option.option} />;
				})
			}
		</div>
	</div>
	;
}
