import React, { useReducer, useState, useLayoutEffect ,useRef,useEffect} from 'react';
import wodService from '../../services/wod.service';
import colorService from '../../services/color.service';

import { Helmet } from 'react-helmet-async';
import {
	Button,
	Modal,
	Form,
	Container,
	Row,
	Col,
	Card,
	Stack
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import AvatarImageCropper from 'react-avatar-image-cropper';
import DateTimePicker from 'react-datetime-picker';
import { wodsCategories,wodsTypes } from '../../utils/lists';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaPlusCircle,FaRegTrashAlt } from 'react-icons/fa';
import moment from 'moment';
import useExercises from '../../hooks/useExercises';
import useQueryFilters from '../../hooks/useQueryFilters';
import eventService from '../../services/event.service';
import ExerciseEditor from '../AdminExercises/ExerciseEditor/ExerciseEditor';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_EVENTS_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_EVENTS_SUCCESS':
		return {
			...state,
			events: action.payload,
			loadingFetch: false,
		};
	case 'FETCH_EVENTS_FAIL':
		return { ...state, loadingFetch: false, error: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };

	default:
		return state;
	}
};

function NewWOD() {
	const navigate = useNavigate();
	const { searchParams } = useQueryFilters({ baseUrl: '/AdminScreen/exercises' });
	const { exercises, loading, reloadExercises } = useExercises(searchParams);
	
	const [editorIsOpen, setEditorIsOpen] = useState(false);
	const openEditor = () => setEditorIsOpen(true);
	const closeEditor = () => {
		setEditorIsOpen(false);
		setSelectedExercise(null);
	};

	const firstLoad = useRef(true);

	const [state, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: '',
		colors:[],
		events:[]
	});

	const { loadingFetch, loadingCreate ,events} = state;
	
	const [image, setImage] = useState('');
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [date, setDate] = useState(new Date());
	const [timeCap, setTimeCap] = useState(1);
	const [rounds, setRounds] = useState(1);
	const [category, setCategory] = useState();
	const [type, setType] = useState();
	const [imageData, setImageData] = useState(null);
	const [fileUrl, setFileUrl] = useState(null);
	const [exercisesList, setExercisesList] = useState([]);
	const [eventsList, setEventsList] = useState([]);
	const [colorsList,setColorsList]=useState([]);
	const [selectedExercise, setSelectedExercise] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState([]);
	const [reps, setReps] = useState('');
	const [weight, setWeight] = useState('');
	const [imageOption, setImageOption] = useState('upload');

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const fetchColors = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const colors = await colorService.getColors();
			setColorsList(colors);
			dispatch({ type: 'FETCH_SUCCESS', payload: colors });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
		}
	};

	const fetchClases = async () => {
		try {
			dispatch({ type: 'FETCH_EVENTS_REQUEST' });
			const events = await eventService.getFilteredEvents('', 1, date.toISOString(), date.toISOString());
			dispatch({ type: 'FETCH_EVENTS_SUCCESS', payload: events });
		} catch (err) {
			dispatch({ type: 'FETCH_EVENTS_FAIL', payload: getError(err) });
		}
	};
	

	useLayoutEffect(() => {
		fetchColors();
		fetchClases();
	}, []);

	useEffect(() => {
		if (!firstLoad.current) {
			reloadExercises();
		} else {
			firstLoad.current = false;}
	}, []);

	useEffect(() => {
		fetchClases();
	}, [date]);


	const addWODImgHandler = async (file) => {
		try {
			const formData = new FormData();
			formData.append('file', file, `WOD${moment().format('YYYYMMDDhhmmss')}`);
			formData.append('documentId', image);
			dispatch({ type: 'CREATE_REQUEST' });
			const response = await wodService.uploadWodImage(formData);
			const imageId = response._id;
			setImage(imageId);
			setFileUrl(response.fileLink);
			toast.success('imagen guardada correctamente');
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	const handleDateChange = (newDate) => {
		const momentDate = moment(newDate);
		momentDate.startOf('day');
		const updatedDate = momentDate.toDate();
		setDate(updatedDate);
	};
	
	const addWodHandler = async () => {
		if (!name || !date || !timeCap || !rounds || !category || !type || !exercisesList || !image, !eventsList) {
			toast.error('Para crear WOD debe completar la informacion y subir o generar y guardar la imagen');
			return;
		}
		try {
			const eventsIds = eventsList.map(event => ({ event: event._id }));

			dispatch({ type: 'CREATE_REQUEST' });
			await wodService.createWod(name, description,image,date,timeCap,rounds,category[0],type[0],exercisesList,eventsIds);
			toast.success('WOD creado');
			navigate('/AdminScreen/WODs');
		} catch (ex) {
			toast.error(getError(ex));
		}
		handleClose();
	};

	//add exercise to the list in the wod
	const handleAddExercise = () => {
		if (selectedExercise.length > 0 && reps && weight) {
			setExercisesList([
				...exercisesList,
				{ exercise: selectedExercise[0], reps, weight }
			]);
			setSelectedExercise([]); 
			setReps(''); 
			setWeight('');
		}
	};
	//remove exercise to the list in the wod
	const handleRemoveExercise = (index) => {
		const updatedExercisesList = exercisesList.filter((_, i) => i !== index);
		setExercisesList(updatedExercisesList);
	};

	const handleExerciseDetailChange = (index, field, value) => {
		const updatedExercisesList = [...exercisesList];
		updatedExercisesList[index][field] = value;
		setExercisesList(updatedExercisesList);
	};

	//add exercise to the list in the wod
	const handleAddEvent = () => {
		if (selectedEvent.length > 0 ) {
			setEventsList([
				...eventsList,
				{_id: selectedEvent[0]._id, subject:selectedEvent[0].subject}
			]);
			setSelectedEvent([]); 
		}
	};
		//remove exercise to the list in the wod
	const handleRemoveEvent = (index) => {
		const updatedEventsList = eventsList.filter((_, i) => i !== index);
		setEventsList(updatedEventsList);
	};

	const generateImage = async (name, date, type, category, rounds, exercisesList) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
	
		canvas.width = 800;
		canvas.height = 600; 
	
		ctx.fillStyle = getRandomColorFromDB();
		ctx.fillRect(0, 0, canvas.width, canvas.height);
	
		ctx.textAlign = 'center';
	
	
		const titleFontSize = 35;
		const subTitleFontSize = 30;
		const bodyFontSize = 24;
		const titleColor = getRandomColorFromDB(); 
		const exerciseColor = getRandomColorFromDB();
		const customFont = 'Verdana'; 
	
		const drawText = (text, x, y, fontSize, fontColor, fontFamily, fontWeight = 'normal') => {
			ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
			ctx.fillStyle = fontColor;
			ctx.textAlign = 'center';
			ctx.fillText(text, x, y);
		};
	
		drawText(`${name}`, canvas.width / 2, 50, titleFontSize, titleColor, customFont, 'bold');
	
		drawText(`${type}/${category}`, canvas.width / 2, 100, subTitleFontSize, titleColor, customFont);
		drawText(`TimeCap: ${timeCap}'   Rounds: ${rounds}`, canvas.width / 2, 140, subTitleFontSize, titleColor, customFont);
	
		ctx.font = `${bodyFontSize}px ${customFont}`;
		ctx.fillStyle = exerciseColor;
		ctx.textAlign = 'left';
	
		let yOffset = 200; 
		exercisesList.forEach((exercise) => {
			ctx.fillText(`-${exercise.exercise.name} - Peso: ${exercise.weight}kg - Reps: ${exercise.reps}`, 50, yOffset);
			yOffset += 35; 
		});
		const imgData = canvas.toDataURL('image/png');

		return imgData;
	};
	
	const getRandomColorFromDB = () => {
		const colorValues = Object.values(colorsList);
		return colorValues[Math.floor(Math.random() * colorValues.length)];
	};
	
	const handleGenerateImage = async () => {
		if(exercisesList.length===0|| !name || !type || !category || !rounds){
			toast.error('Para generar la imagen debe completar todos los datos');
			return;
		}
		const img = await generateImage(name, date, type[0], category[0], rounds, exercisesList);
		setImageData(img);
	};

	const saveWODImage = async () => {
		if(!imageData){
			toast.error('Para guardar la imagen, primero debe presionar generar imagen');
			return;
		}
		const byteString = atob(imageData.split(',')[1]);
		const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
	
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([ab], { type: mimeString });
		const file = new File([blob], `WOD${moment().format('YYYYMMDDhhmmss')}.png`, { type: mimeString });
	
		addWODImgHandler(file);
	};

	const onSuccessCreate = () => {
		reloadExercises();
	};
	
	
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && loading && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear WOD</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear WOD?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addWodHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<div>
				<ExerciseEditor exerciseParam={selectedExercise}
					show={editorIsOpen} close={closeEditor} success={onSuccessCreate} />;
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear WOD</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Crear WOD
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()} className='col'>
												<Row>
													<Form.Group className="mb-3 col-lg-4" controlId="fecha">
														<Form.Label>Fecha</Form.Label>
														<br></br>
														<DateTimePicker
															className="timePicker"
															onChange={handleDateChange}
															value={date}
															clearIcon={null}
															required={true}
															disableClock={true}
															format="dd-MM-y"
															minDate={new Date()}
														/>
													</Form.Group>
													<Form.Group className="mb-3 col-lg-8" controlId="nombre">
														<Form.Label>Nombre</Form.Label>
														<Form.Control
															onChange={(e) => setName(e.target.value)}
														></Form.Control>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3 col-lg-6" controlId="timeCap">
														<Form.Label>Time Cap(min)</Form.Label>
														<Form.Control
															type="number"
															min={0}
															onChange={(e) => setTimeCap(e.target.value)}
														></Form.Control>
													</Form.Group>
													<Form.Group className="mb-3 col-lg-6" controlId="rondas">
														<Form.Label>Rondas</Form.Label>
														<Form.Control
															type="number"
															min={0}
															onChange={(e) => setRounds(e.target.value)}
														></Form.Control>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3 col-lg-6" controlId="category">
														<Form.Label>Categoria</Form.Label>
														<Typeahead
															id="category-typeahead"
															onChange={(selected) => setCategory(selected)}
															options={wodsCategories}
															placeholder="Seleccione la categoría"
															selected={category}
														/>
													</Form.Group>
													<Form.Group className="mb-3 col-lg-6" controlId="type">
														<Form.Label>Tipo</Form.Label>
														<Typeahead
															id="type-typeahead"
															onChange={(selected) => setType(selected)}
															options={wodsTypes}
															placeholder="Seleccione el tipo"
															selected={type}
														/>
													</Form.Group>
												</Row>
												<Form.Group className="mb-3 col-lg-12" controlId="descripcion">
													<Form.Label>Descripcion</Form.Label>
													<Form.Control
														as="textarea"
														rows={2}
														onChange={(e) => setDescription(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Card className='m-3 p-4'>
													<h2 className='mb-3'>Lista de ejercicios</h2>
													<Row>
														<Form.Group className="col-lg-6">
															<Form.Label>Buscar Ejercicio</Form.Label>
															<Typeahead
																id="exercise-search"
																options={[
																	...exercises,
																	{ _id: 'add-new', name: 'Agregar nuevo ejercicio', emptyOption: true }
																]}
																placeholder="Seleccione un ejercicio"
																labelKey="name"
																selected={selectedExercise}
																onChange={(selected) => {
																	if (selected[0] && selected[0]._id === 'add-new') {
																		openEditor();
																	} else {
																		setSelectedExercise(selected);
																	}
																}}
															/>
															
														</Form.Group>
														<Form.Group className="col-lg-2">
															<Form.Label>Reps</Form.Label>
															<Form.Control
																type="number"
																value={reps}
																onChange={(e) => setReps(e.target.value)}
															/>
														</Form.Group>
														<Form.Group className="col-lg-2">
															<Form.Label>Peso</Form.Label>
															<Form.Control
																type="number"
																value={weight}
																onChange={(e) => setWeight(e.target.value)}
															/>
														</Form.Group>
														<Col lg={2} className="d-flex align-items-end">
															<Button
																variant="primary"
																onClick={handleAddExercise}
																disabled={!selectedExercise?.length || !reps || !weight}
															>
																<FaPlusCircle/>
															</Button>
														</Col>
													</Row>
													{exercisesList.map((item, index) => (
														<Row key={index} className="mb-3">
															<Form.Group className="col-lg-6">
																<Form.Label>Ejercicio</Form.Label>
																<Form.Control
																	type="text"
																	value={item.exercise.name}
																	readOnly
																/>
															</Form.Group>
															<Form.Group className="col-lg-2">
																<Form.Label>Reps</Form.Label>
																<Form.Control
																	type="number"
																	value={item.reps}
																	onChange={(e) =>
																		handleExerciseDetailChange(index, 'reps', e.target.value)
																	}
																/>
															</Form.Group>
															<Form.Group className="col-lg-2">
																<Form.Label>Peso</Form.Label>
																<Form.Control
																	type="number"
																	value={item.weight}
																	onChange={(e) =>
																		handleExerciseDetailChange(index, 'weight', e.target.value)
																	}
																/>
															</Form.Group>
															<Col lg={2} className="d-flex align-items-end">
																<Button
																	variant="primary"
																	onClick={() => handleRemoveExercise(index)}
																>
																	<FaRegTrashAlt/>
																</Button>
															</Col>
														</Row>
													))}
												</Card>
												<Form.Group className='mb-3 d-flex flex-column align-items-center' controlId='imageOption'>
													<Form.Label>Opción de Imagen</Form.Label>
													<Form.Check
														label='Subir Imagen'
														name='imageOption'
														type='radio'
														id='upload'
														checked={imageOption === 'upload'}
														onChange={() => setImageOption('upload')}
													/>
													<Form.Check
														label='Generar Imagen'
														name='imageOption'
														type='radio'
														id='generate'
														checked={imageOption === 'generate'}
														onChange={() => setImageOption('generate')}
													/>
												</Form.Group>
												{imageOption === 'upload' && (
													<Form.Group className="mb-3" controlid="image">
														<Form.Label>Imagen:</Form.Label>
														<div className="row align-items-centers">
															<div
																style={{
																	padding: 0,
																	width: '300px',
																	height: '300px',
																	margin: 'auto',
																	border: '1px solid black',
																	backgroundImage:
																	image && fileUrl
																		? 'url(' + fileUrl + ')'
																		: 'url(/images/emptyPhoto.png)',
																	backgroundRepeat: 'no-repeat',
																	backgroundSize: '100% auto',
																	backgroundPosition: 'center top'
																}}
															>
																<AvatarImageCropper
																	apply={addWODImgHandler}
																	text="Adjuntar la imagen"
																/>
															</div>
														</div>
													</Form.Group>)}
												{imageOption === 'generate' && (
													<Card.Body>
														<Row>
															{imageData && <img src={imageData} alt="Generated"                                 style={{
																padding: 0,
																width: '300px',
																height: '300px',
																margin: 'auto',
																border: '1px solid black',
															}}/>}
														</Row>
														<Row>										
															<Button className='col-2 mt-3' onClick={handleGenerateImage}>Generar WOD</Button>
															<Button className='col-2 mt-3' disabled={!imageData} onClick={saveWODImage}>Guardar imagen</Button>
														</Row>
													</Card.Body>)}
												<Card className='m-3 p-4'>
													<h2 className='mb-3'>Clases del día</h2>
													<Row>
														<Form.Group className="col-lg-6">
															<Form.Label>Buscar clase</Form.Label>
															<Typeahead
																id="event-search"
																options={[
																	...events,
																]}
																placeholder="Seleccione una clase"
																labelKey="subject"
																selected={selectedEvent}
																onChange={(selected) => {
																	setSelectedEvent(selected);
																}}
															/>
															
														</Form.Group>
														<Col lg={2} className="d-flex align-items-end">
															<Button
																variant="primary"
																onClick={handleAddEvent}
																disabled={!selectedEvent.length}
															>
																<FaPlusCircle/>
															</Button>
														</Col>
													</Row>
													{eventsList.map((item, index) => (
														<Row key={index} className="mb-3">
															<Form.Group className="col-lg-6">
																<Form.Label>Evento</Form.Label>
																<Form.Control
																	type="text"
																	value={item.subject}
																	readOnly
																/>
															</Form.Group>
															<Col lg={2} className="d-flex align-items-end">
																<Button
																	variant="primary"
																	onClick={() => handleRemoveEvent(index)}
																>
																	<FaRegTrashAlt/>
																</Button>
															</Col>
														</Row>
													))}
												</Card>
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Crear WOD
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default NewWOD;
