import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
	Card,
	InputGroup
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import SearchBox from '../../components/SearchBox/SearchBox';
import eventService from '../../services/event.service';

import {
	BsPlusCircle,
	BsTrash,
	BsPencilSquare,
	BsFileEarmarkRichtext
} from 'react-icons/bs';
import { BsFillCalendarEventFill } from 'react-icons/bs';
import { DateTime } from 'luxon';
import './AdminEventList.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
	HiBarsArrowDown,
	HiBarsArrowUp,
	HiOutlineFunnel,
	HiOutlineXMark
} from 'react-icons/hi2';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			events: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload ? action.payload.length : 0
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

// Fecha actuales (luxon)
const monday = DateTime.now().startOf('week'); // isoWeek is equivalent to 'week' in luxon
const sunday = DateTime.now().endOf('week');

function AdminEventList() {
	const [
		{ loadingFetch, error, events, successDelete, itemQuantity },
		dispatch
	] = useReducer(reducer, {
		events: [],
		error: '',
		itemQuantity: 0,
		loadingFetch: true
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const { search } = useLocation();
	const sp = new URLSearchParams(search); // /search?category=Shirts
	const query = sp.get('subjectQuery') || '';

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = events.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = events.slice(indexOfFirstPost, indexOfLastPost);
	};
	//set date-range-picker initial dates
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(monday.toJSDate()),
			endDate: new Date(sunday.toJSDate()),
			key: 'selection'
		}
	]);

	const [startDate, setStartDate] = useState(monday.toFormat('yyyy-MM-dd'));
	const [endDate, setEndDate] = useState(sunday.toFormat('yyyy-MM-dd'));
	const [dateSort, setDateSort] = useState(1);
	const [showDropdown, setShowDropdown] = useState(false);

	const handleDropdownToggle = (isOpen) => {
		setShowDropdown(isOpen);
	};

	//filter button functionality
	async function handleSelect() {
		setShowDropdown(false);
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const data = await eventService.getFilteredEvents(
				query,
				dateSort,
				startDate,
				endDate
			);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await eventService.getFilteredEvents(
					query,
					dateSort,
					startDate,
					endDate
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, userInfo.token, dateSort, query]);

	async function toggleEventHandler(event) {
		if (
			window.confirm(
				`Seguro desea ${event.isActive ? 'desactivar' : 'activar'} este evento?`
			)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await eventService.toggleEventActiveStatus(event._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success(
					`Evento  ${event.subject} ${event.isActive ? 'desactivado' : 'activado'}`
				);
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editEventHandler(user) {
		navigate(`/AdminScreen/editEvent/${user._id}`);
	}

	async function detailsEventHandler(user) {
		navigate(`/AdminScreen/detailEvent/${user._id}`);
	}

	async function newEventHandler() {
		navigate('/AdminScreen/newEvent');
	}

	//Calendar range picker set dates
	const handler = (item) => {
		if (item.selection.startDate && item.selection.endDate) {
			setDateRange([item.selection]);
			setStartDate(
				DateTime.fromJSDate(item.selection.startDate).toFormat('yyyy-MM-dd')
			);
			setEndDate(
				DateTime.fromJSDate(item.selection.endDate).toFormat('yyyy-MM-dd')
			);
		}
	};

	return (
		<div>
			<div>
				<Helmet>
					<title>Eventos y Clases</title>
				</Helmet>
				<div className="container admin-con">
					<div className="tableResponsive">
						<Row style={{ margin: '5% 0 2.5%', alignItems: 'center' }}>
							<Col>
								<h1 className="section-title text-right">
									<BsFillCalendarEventFill></BsFillCalendarEventFill>Lista de Eventos
								</h1>
							</Col>
							<InputGroup className="mb-3" controlid="fechaInicio">
								<br></br>
							</InputGroup>
							<Col
								style={{
									display: 'flex',
									justifyContent: 'flex-start'
								}}
							>
								<DropdownButton
									id="optionsLists"
									drop="bottom"
									title="Filtrar por Fechas"
									className="m-1 fixed-left"
									autoClose={false}
									show={showDropdown}
									onToggle={handleDropdownToggle}
								>
									<Dropdown.Item className="calendarDropdownItem" eventKey="0">
										<Row>
											<DateRange
												editableDateInputs={true}
												onChange={(item) => handler(item)}
												showSelectionPreview={true}
												ranges={dateRange}
												className="my-datetime-picker"
											/>
										</Row>
										<Row>
											<Button onClick={handleSelect}>
												<HiOutlineFunnel></HiOutlineFunnel>Filtrar
											</Button>
										</Row>
									</Dropdown.Item>
								</DropdownButton>
								<Button
									className="btn btn-dark m-1 fixed-left filterBtn"
									value="Crear evento"
									onClick={() => (dateSort === 1 ? setDateSort(-1) : setDateSort(1))}
								>
									{dateSort === -1 ? <HiBarsArrowUp /> : <HiBarsArrowDown />}
									Ordenar
								</Button>
							</Col>
							<Col
								className="col-10 col-md-6 col-lg-4"
								style={{ height: '52px', padding: '2px' }}
							>
								<SearchBox></SearchBox>
								<Link to="/AdminScreen/eventList" style={{ fontSize: '10px' }}>
									<HiOutlineXMark></HiOutlineXMark>borrar busqueda
								</Link>
							</Col>
							<Col
								style={{
									display: 'flex',
									justifyContent: 'flex-end'
								}}
							>
								<Button
									className="btn btn-dark m-1 fixed-right"
									value="Crear evento"
									onClick={() => newEventHandler()}
								>
									<span>
										<BsPlusCircle /> Agregar evento
									</span>
								</Button>
							</Col>
						</Row>
						<Card>
							<Table bordered hover responsive size="sm">
								{loadingFetch && <LoadingBox></LoadingBox>}
								{error && <MessageBox variant="danger">{error}</MessageBox>}
								<thead>
									<tr>
										<th className="col-md-2">Tema</th>
										<th className="col-md-2">Locacion</th>
										<th className="col-md-1">Capacidad</th>
										<th className="col-md-1">Fecha y hora de inicio</th>
										<th className="col-md-1">Fecha y hora de fin</th>
										<th className="col-1">Opciones</th>
									</tr>
								</thead>
								{events
									? currentPosts.map((event) => (
										<tbody key={event._id}>
											<tr
												key={event._id}
												className={`align-items-center table-order ${
													event.isActive ? 'table-success' : 'table-secondary'
												}`}
												id="data"
											>
												<td className="col-md-2">{event.subject}</td>
												<td className="col-md-2">{event.location}</td>
												<td className="col-md-1">{event.capacity}</td>
												<td className="col-md-1">
													{DateTime.fromISO(event.eventStartDateTime).toUTC().toFormat(
														'dd/MM/yyyy HH:mm a'
													)}
												</td>
												<td className="col-md-1">
													{DateTime.fromISO(event.eventEndDateTime).toUTC().toFormat(
														'dd/MM/yyyy HH:mm a'
													)}
												</td>
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														<Dropdown.Item
															eventKey="0"
															onClick={() => detailsEventHandler(event)}
														>
															<BsFileEarmarkRichtext />
																Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="1"
															onClick={() => editEventHandler(event)}
														>
															<BsPencilSquare />
																Editar
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="2"
															onClick={() => toggleEventHandler(event)}
														>
															<BsTrash />
																Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>
									))
									: ''}
							</Table>
						</Card>
						<Pagination
							className="pagination-bar"
							totalCount={itemQuantity}
							onPageChange={onPageChange}
							currentPage={currentPage}
							pageSize={maxitemsPerPage}
						></Pagination>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminEventList;
