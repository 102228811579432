import membershipService from '../../../services/membership.service';
import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError, maxitemsPerPage } from '../../../utils';
import moment from 'moment';
import LoadingBox from '../../../components/LoadingBox';
import MessageBox from '../../../components/MessageBox';
import Pagination from '../../../components/Pagination/Pagination';
import {
	BsTrash,
	BsPencilSquare,
	BsFileEarmarkRichtext,
} from 'react-icons/bs';
import classnames from 'classnames';
import UserMembershipAssigner from '../MembershipAssignament/UserMembershipAssigner';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			bookings: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UserMembershipsList({ className }) {
	const params = useParams();
	const { id } = params;
	const [
		{ loading, error, bookings, successDelete, itemQuantity },
		dispatch
	] = useReducer(reducer, {
		bookings: [],
		loading: true,
		itemQuantity: 0,
		error: ''
	});
	const navigate = useNavigate();

	const [showMembershipAssigner, setShowMembershipAssigner] = useState(false);


	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	};

	const fetchData = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const data = await membershipService.findByUserId(id);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};
	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, id]);

	async function toggleUserBookingHandler(booking) {
		if (
			window.confirm(
				`Seguro desea ${booking.isActive ? 'desactivar' : 'activar'} esta cuponera?`
			)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await membershipService.toggleMembershipStatus(booking._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success(
					`Cuponera de ${booking.template.name} ${booking.isActive ? 'desactivada' : 'activada'}`
				);
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editUserBookingHandler(booking) {
		navigate(`/AdminScreen/editUserBooking/${booking._id}`);
	}

	async function detailsUserBookingHandler(booking) {
		navigate(`/AdminScreen/userbookingdetails/${booking._id}`);
	}

	async function assignMembershipHandler() {
		setShowMembershipAssigner(true);
	}
	async function onSuccess() {
		setShowMembershipAssigner(false);
		fetchData();
	}


	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<UserMembershipAssigner show={showMembershipAssigner} onSuccess={onSuccess} handleClose={() => setShowMembershipAssigner(false)} />
			{/* <FormCreationModal title = "Asignar cuponera" show={showMembershipAssigner} onHide={() => setShowMembershipAssigner(false)}
				data={data} dataName='Cuponera' /> */}
			<div className={classnames('user-mebership-list', { [className]: className })}>
				<Row>
					<Col className='d-flex justify-content-end mb-2'
					>
						<Button
							className="btn btn-dark m-1 fixed-left"
							value="Volver"
							onClick={() => assignMembershipHandler()}
						>
							<span>Asignar Cuponera</span>
						</Button>
					</Col>
				</Row>
				{loading ? (
					<LoadingBox></LoadingBox>
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<Table bordered hover responsive size="sm">
						<thead>
							<th className="col-md-2">
								<span>Cuponera</span>
							</th>
							<th className="col-md-2">Clases restantes</th>
							<th className="col-md-2">Estado</th>
							<th className="col-md-2">Fecha de inicio</th>
							<th className="col-md-2">Fecha de vencimiento</th>
							<th className="col-1">Opciones</th>
						</thead>
						{bookings ?
							<tbody>
								{currentPosts.map((booking) => (
									<tr key={booking._id}
										className={`align-items-center table-order ${booking.isActive ? '' : 'table-secondary'
										}`}
										id="data"
									>
										<td className="col-md-2">{booking.template.name}</td>
										<td className="col-md-2">{booking.bookingsLeft}</td>
										<td className="col-md-2">{`${booking.isActive ? 'activa' : 'inactiva'}`}</td>
										<td className="col-md-2">
											{moment(booking.startingDate).format('DD/MM/YYYY')}
										</td>
										<td className="col-md-2">
											{moment(booking.dueDate).format('DD/MM/YYYY')}
										</td>
										<td>
											<DropdownButton id="optionsLists" drop="start" title="">
												<Dropdown.Item
													eventKey="0"
													onClick={() => detailsUserBookingHandler(booking)}
												>
													<BsFileEarmarkRichtext />
													Ver Detalles
												</Dropdown.Item>
												<Dropdown.Item
													eventKey="1"
													onClick={() => editUserBookingHandler(booking)}
												>
													<BsPencilSquare />
													Editar
												</Dropdown.Item>
												<Dropdown.Item
													eventKey="2"
													onClick={() => toggleUserBookingHandler(booking)}
												>
													<BsTrash />
													Eliminar
												</Dropdown.Item>
											</DropdownButton>
										</td>
									</tr>
								))}
							</tbody>
							: ''}
					</Table>
				)}
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</>
	);
}

export default UserMembershipsList;
