import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Exercise from '../../../classes/Exercise';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import _exercises from '../../../services/exercise.service';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';

export default function ExerciseEditor({ show, close, success, exerciseParam = {} }) {
	const [exercise, setExercise] = useState(exerciseParam ? Exercise.fromObject(exerciseParam) : Exercise.createEmpty());
	useEffect(() => {
		if (exerciseParam) {
			setExercise(Exercise.fromObject(exerciseParam));
		}
	}, [exerciseParam]);

	const handleCreate = async (event) => {
		event.preventDefault();
		if (exercise.isValid()) {
			try {
				await _exercises.create(exercise.name, exercise.videoUrl, exercise.image._id);
				toast.success('Ejercicio creado con éxito.');
				onSuccess();
			} catch (ex) {
				console.error(ex);
				console.error(getError(ex));
				toast.error(getError(ex));
			}
		} else {
			toast.error('Error. Revise los datos ingresados.');
		}
	};
	const handleEdit = async (event) => {
		event.preventDefault();
		if (exercise.isValid()) {
			try {
				await _exercises.edit(exercise._id, exercise.name, exercise.videoUrl, exercise.image._id);
				toast.success('Cambios guardados con éxito.');
				onSuccess();
			} catch (ex) {
				console.error(ex);
				toast.error(getError(ex));
			}
		} else {
			toast.error('Error. Revise los datos ingresados.');
		}
	};


	const setExerciseProperty = (field, value) => {
		setExercise(Exercise.fromObject({ ...exercise, [field]: value }));
	};
	const uploadImage = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			let r = Math.random().toString(36).substring(7);
			formData.append('file', file, file.name.replaceAll(' ') + r);
			const { data: image } = await _exercises.uploadImage(formData);
			setExerciseProperty('image', image);
		}
	};
	const onClose = () => {
		setExercise(Exercise.createEmpty());
		close();
	};

	const onSuccess = () => {
		success();
		onClose();
	};

	return (
		<Modal show={show} onHide={onClose} animation={false} dialogClassName='exercise-editor'>
			<Modal.Header closeButton>
				<Modal.Title>{exerciseParam?.name ? 'Editar' : 'Crear'} ejercicio</Modal.Title>
			</Modal.Header>
			<Form onSubmit={exerciseParam?._id ? handleEdit : handleCreate}>
				<Modal.Body className='justify-content-center'>
					<FloatingLabel
						controlId='exerciseName'
						label='Nombre del ejercicio'
					>
						<Form.Control type='text' value={exercise.name}
							onChange={(e) => setExerciseProperty('name', e.target.value)}
							placeholder='Nombre del ejercicio' />
					</FloatingLabel>
					<FloatingLabel
						controlId='exercisevideoUrl'
						label='URL de video'
					>
						<Form.Control type='text' value={exercise.videoUrl}
							onChange={(e) => setExerciseProperty('videoUrl', e.target.value)}
							placeholder='URL de video' />
					</FloatingLabel>
					<Form.Group className='mb-3' controlId='exerciseImage'>
						<Form.Label>Subir imagen</Form.Label>
						<Form.Control
							onChange={uploadImage}
							type='file'
							name='exerciseImage'
						></Form.Control>
					</Form.Group>
					<div className='col-12 d-flex justify-content-center'>
						<img src={exercise?.image?.fileLink || '/images/emptyPhoto.png'}
							alt='exercise_photo'
							className='img-preview mw-100' />
					</div>
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-between'>
					<Button variant='secondary' onClick={onClose}>
                        Atrás
					</Button>
					<Button variant='primary' className='m-0' type='submit'>
                        Guardar
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};