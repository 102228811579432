import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Store } from '../../../../Store.js';
import './Product.css';
import { toast } from 'react-toastify';
import _products from '../../services/product.service';

function Product(props) {
	const { product, productType } = props;
	const navigate = useNavigate();
	const { state, dispatch: cxtDispatch } = useContext(Store);
	const {
		cart: { cartItems }, useStock
	} = state;


	const addToCartHandler = async (item) => {
		const { data } = await _products.get(item._id, productType);
		const existingProduct = cartItems.filter((cartItem) => cartItem._id === product._id);
		const existItem = !product.option || product.options.length === 0 ? existingProduct[0] : cartItems.find((cartItem) => cartItem.option === option);
		const quantity = existItem ? existItem.quantity + numOfProd : numOfProd;
		let totalItemsQuantity = quantity;
		if (existingProduct.length > 1) {
			totalItemsQuantity = existingProduct.reduce((totalQty, { quantity }) => totalQty + quantity, 0) + numOfProd;
		}

		if (!useStock || data.countInStock >= totalItemsQuantity) {
			cxtDispatch({ type: 'CART_ADD_ITEM', payload: { ...item, quantity, option } });
			setNumOfProd(1);
		} else {
			toast.warning('Se ha acabado el stock.');
		}
	};

	const [numOfProd, setNumOfProd] = useState(1);
	const [option, setOption] = useState('');
	// eslint-disable-next-line no-unused-vars
	const onSelectOption = (eventKey, event) => {
		event.preventDefault();
		event.persist();
		event.stopPropagation();
		setOption(eventKey);
	};
	const updateCartHandlerPlus = async () => {
		setNumOfProd(numOfProd + 1);
	};
	const updateCartHandlerMinus = async () => {
		setNumOfProd(numOfProd - 1);
	};

	return (
		<div className="product " key={product.slug}>
			<Card>
				<Link to={`/product/${product.slug}`}>
					{product.image && typeof product.image === 'object' ? (
						<img src={product.image.fileLink} alt={product.name} />
					) : (
						<img alt="product_photo" src="/images/emptyPhoto.png" />
					)}
				</Link>
				<Card.Body className="product-card-body">
					<div className="product-card-data">
						<Link to={`/product/${product.slug}`}>
							<h5>{product.name}</h5>
						</Link>
						<h6>{product.brand}</h6>
						<Card.Text>{product.priceRange || '$' + product.price}</Card.Text>
					</div>
					<div>
						{(useStock && product.countInStock > 0 && !product.hasVariants) && (
							<>
								<Button
									variant="light"
									onClick={() => updateCartHandlerMinus()}
									disabled={+numOfProd === 1}
								>
									<i className="fas fa-minus-circle"></i>
								</Button>{' '}
								<span>{+numOfProd}</span>{' '}
								<Button
									onClick={() => updateCartHandlerPlus()}
									variant="light"
									disabled={useStock ? numOfProd === product.countInStock : false}
								>
									<i className="fas fa-plus-circle"></i>
								</Button>
							</>
						)}
					</div>
					{(useStock && product.countInStock === 0) ? (
						<Button variant="light" disabled>
              Sin stock
						</Button>
					) : product.hasVariants ? (
						<button
							onClick={() => navigate(`/product/${product.slug}`)}
							className="product-button btn btn-primary"
						>
              Ver opciones
						</button>
					) : (
						<button
							onClick={() => addToCartHandler(product)}
							className="product-button btn btn-primary"
						>
              Agregar al Carrito
						</button>
					)}
				</Card.Body>
			</Card>
		</div>
	);
}

export default Product;
