import React from 'react';
import classnames from 'classnames';
import './UserBasicData.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoPersonCircleOutline, IoMailOutline, } from 'react-icons/io5';
import { CiPhone } from 'react-icons/ci';



export default function UserBasicData({ className, user }) {
	return <div className={classnames('user-basic-data', { [className]: className })}>

		<Row className='h-100 w-100'>
			<Col xs={7} sm={5} md={4} as='figure'>
				<img src="/images/user-default-image.png" />
			</Col>
			<Col className='basic-data' xs={5} sm={7} md={8} as={Row}>
				{user &&
					<>
						<h1>{user.name}</h1>
						<div className='data-line'><IoPersonCircleOutline /> <span>{user.role}</span></div>
						<div className='data-line'> <IoMailOutline /> <span>{user.email}</span></div>
						{user.phoneNumber && <div className='data-line'> <CiPhone /> <span>{user.phoneNumber}</span></div>	}
						
					</>
				}
			</Col>
		</Row>
	</div>;
}
