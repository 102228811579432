import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
} from 'react-bootstrap';
import { getError, maxitemsPerPage } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import {
	BsTrash,
	BsPencilSquare,
} from 'react-icons/bs';
import classnames from 'classnames';
import _rm from '../../services/rm.service';



function UserRms({ className }) {
	const { id } = useParams();
	const navigate = useNavigate();
	const [rms, setRms] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = rms.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = rms.slice(indexOfFirstPost, indexOfLastPost);
	};

	const fetchRmData = async () => {
		try {
			const data = await _rm.getRmsByUserId(id);
			setRms(data);
			currentPosts = rms.slice(indexOfFirstPost, indexOfLastPost);
		} catch (err) {
			console.error(err);
			setError(getError(err));
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchRmData();
	}, [id]);

	async function newRmHandler() {
		navigate(`/AdminScreen/newRm/${id}`);
	}
	async function editRmHandler() {
		navigate(`/AdminScreen/editRm/${id}`);
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div className={classnames('user-mebership-list', { [className]: className })}>
				<Row>
					<Col className='d-flex justify-content-end mb-2'
					>
						<Button
							className="btn btn-dark m-1 fixed-left"
							value="Volver"
							onClick={newRmHandler}
						>
							<span>Nuevo RM</span>
						</Button>
					</Col>
				</Row>
				{loading ? (
					<LoadingBox></LoadingBox>
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : rms.length ? (
					<Table bordered hover responsive size="sm">
						<thead>
							<th>Ejercicio</th>
							<th>Peso</th>
							<th>Cantidad de repeticiones</th>
							<th>Fecha</th>
							<th></th>
						</thead>
						<tbody>
							{currentPosts.map((rm) => {
								return <tr key={rm._id}>
									<td>{rm.exercise.name}</td>
									<td>{rm.weight}</td>
									<td>{rm.maxRep}</td>
									<td>{rm.date}</td>
									<td>
										<DropdownButton id="optionsLists" drop="start" title="">
											<Dropdown.Item
												eventKey="1"
												onClick={() => editRmHandler(rm)}
											>
												<BsPencilSquare />
													Editar
											</Dropdown.Item>
											<Dropdown.Item
												eventKey="2"
												//onClick={() => toggleUserrmHandler(rm)}
											>
												<BsTrash />
													Eliminar
											</Dropdown.Item>
										</DropdownButton>
									</td>
								</tr>;
							})}
						</tbody>
					</Table>
				) : 
					<MessageBox>
					No hay marcas registradas para este usuario
					</MessageBox >
				}
				<Pagination
					className="pagination-bar"
					totalCount={rms.length}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</>
	);
}

export default UserRms;
