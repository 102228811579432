import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function SearchBox() {
	const navigate = useNavigate();
	const [query, setQuery] = useState('');

	const submitHandler = (e) => {
		e.preventDefault();
		navigate(query ? `?subjectQuery=${query}` : '');
	};

	const [isActiveSearch, setActive] = useState('false');

	const handleToggle = () => {
		setActive(!isActiveSearch);
	};

	return (
		<Form className="input-group searchBox" onSubmit={submitHandler}>
			<div className="form-outline">
				<input
					placeholder="Buscar"
					className={
						isActiveSearch
							? 'd-lg-inline d-none  form-control search-input'
							: ' form-control search-input'
					}
					type="text"
					name="q"
					id="q"
					onChange={(e) =>
						setQuery(e.target.value.replace(/[-/\\^$*+?.()|[\]{}]/g, ''))
					}
				></input>
			</div>
			<Button
				type="submit"
				onClick={handleToggle}
				className=" btn btnbtn-primary-search"
			>
				<i className="fas fa-search"></i>
			</Button>
		</Form>
	);
}

export default SearchBox;
